import React, { Fragment } from "react"
import sanityClient from "../config/sanity"
import Meta from "../src/components/Meta"
import { useSanityPreview } from "../src/hooks/useSanityPreview"
import { HomePage } from "../src/routes/HomePage"

export default function Page({ content, query }) {
    const doc = useSanityPreview(content, query)
    if (doc?.__preview__) return <Fragment>Loading preview</Fragment>

    return (
        <Fragment>
            {(content?.meta || content?.title) && (
                <Meta
                    title={content?.meta?.title ?? content.title}
                    {...content.meta}
                />
            )}

            <HomePage content={doc} />
        </Fragment>
    )
}

const singelCase = `*[_type == "frontpage2"] {
    ...,
    integrations[]->,
    feature[]->,
    usecases {
        useCases[]->,
        ...
    }
  }[0]
  `

// This function gets called at build time on server-side.
export const getStaticProps = async ({ params, preview = false }) => {
    const content = await sanityClient.fetch(singelCase)
    return {
        props: {
            content,
            query: `
    cases[]->,
    keyfeature[]->,
    usp[]->,
    icp[]->,
    integrations[]->`,
        },
    }
}
