import React, { useState, useEffect, Fragment } from "react"
import { useRouter } from "next/router"
import { previewClient } from "../../config/sanity"

export const useSanityPreview = (initialDoc, restQuery = "") => {
    const [previewDoc, setSanityDoc] = useState(null)
    const router = useRouter()

    useEffect(() => {
        if (router.query.preview === "true" && router.query.id) {
            const query = `*[_id == "${router.query.id}"]{..., ${restQuery}}`

            previewClient.fetch(query).then(res => {
                if (res[0]) {
                    setSanityDoc(res[0])
                }
            })
        }
    }, [router.query?.preview, router.query?.id])

    if (previewDoc) {
        return previewDoc
    } else {
        return initialDoc
    }
}

export const Preview = ({ children, content }) => {
    const doc = useSanityPreview(content)
    if (doc.__preview__) return <Fragment>Loading preview</Fragment>
    return children
}
